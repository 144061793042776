<template>
  <div class="cart-container">
    <div
      v-if="toggleCart && backdropEnabled"
      class="backdrop"
      @click="closeCart"
    ></div>
    <transition name="slide" mode="out-in">
      <div
        v-if="toggleCart"
        v-click-outside="closeCart"
        class="sidebar-right"
        role="dialog"
        aria-labelledby="cart-sidebar-title"
        :aria-hidden="`${!toggleCart}`"
        @keydown.esc="closeCart"
      >
        <Cart />
      </div>
    </transition>
  </div>
</template>

<script>
import { isEqual } from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'SideCart',
  components: {
    Cart: () => import('~/components/Cart/Cart'),
  },
  computed: {
    toggleCart() {
      return this.$store.getters.toggleCart
    },
    ...mapGetters(['cart', 'backdropEnabled', 'cartIsLoading']),
  },
  mounted() {
    if (!this.cartIsLoading) {
      this.loadCartIfEmpty()
    }

    // Making the addToCart action global for use by other third parties
    window.addToCart = function (payload) {
      this.$nuxt.$store.dispatch('addToCart', payload)
    }
  },
  methods: {
    updateCartState(data, updateId = null) {},
    closeCart() {
      const focusedElement = this.$el.querySelector(':focus')
      if (focusedElement) focusedElement.blur()
      this.$store.dispatch('toggleCart')
    },
    loadCartIfEmpty() {
      if (!this.cart || isEqual(this.cart, {})) {
        this.$store.dispatch('getCart')
      }
    },
  },
}
</script>
<style lang="scss">
.slide-enter-active,
.slide-leave-active {
  transition: transform 250ms ease-out;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
  transition: all 250ms ease-in 0s;
}

.sidebar-right {
  /*transition: right 0.25s ease-in-out;*/
  position: fixed;
  @media (max-width: 1028px) {
    top: 0;
  }
  top: 0;
  right: 0;
  bottom: 0;
  width: rem-calc(423);
  z-index: 10000000;
  background-color: #fff;
  -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.75);
  &.open {
    right: 0;
    @media (max-width: 1028px) {
      top: 0px;
    }
    top: 0px;
    transition: right 0.25s ease-in-out;
  }
  @media (max-width: 640px) {
    right: 0vw;
    width: 90vw;
    left: auto;
  }
}
</style>
